import React, { useState } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Rotate from "react-reveal/Rotate";
import Wobble from "react-reveal/Wobble";
import Fade from "react-reveal/Fade";
import "./App.css";
import ProfilePic from "./assets/profilePic.jpg";
import Spinner from "./assets/spinner.gif";

function App() {
  const [loaded, setLoaded] = useState<boolean>(false);

  const particlesInit = async (main: any) => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <>
      {!loaded && (
        <div className="absolute top-0 w-screen h-screen z-50 bg-white flex flex-col justify-center items-center">
          <img src={Spinner} alt="Loading spinner" />
          Laden...
        </div>
      )}
      <Fade top when={loaded}>
        <div className="w-screen h-screen flex justify-center items-center z-10">
          <div className="card">
            <Rotate top left when={loaded} delay={1000}>
              <div className="imageSection">
                <img
                  src={ProfilePic}
                  alt="Profielfoto van Axel"
                  width="100%"
                  className="profilePic"
                  onLoad={() => {
                    setLoaded(true);
                    console.log("Images loaded");
                  }}
                />
              </div>
            </Rotate>
            <Rotate top right when={loaded} delay={1000}>
              <div className="textSection">
                <Wobble delay={2000} when={loaded}>
                  <p className="text-4xl">👋</p>
                </Wobble>
                <h1>Axel Lemmens</h1>
                <h3>
                  ir. | Technisch consultant | Hulpverlener-Ambulancier | Docent
                </h3>
                <div className="socialIcons">
                  <a
                    href="https://www.linkedin.com/in/lemmensaxel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in text-linkedIn"></i>
                  </a>
                  <a
                    href="https://github.com/lemmensaxel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-github text-github"></i>
                  </a>
                  <a
                    href="https://bitbucket.org/lemmensaxel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-bitbucket text-bitbucket"></i>
                  </a>
                  <a
                    href="https://twitter.com/lemmensaxel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter text-twitter"></i>
                  </a>
                  <a href="mailto:contact@axellemmens.be">
                    <i className="far fa-envelope"></i>
                  </a>
                </div>
              </div>
            </Rotate>
          </div>
          <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
              fullScreen: {
                enable: true,
                zIndex: -1000,
              },
              background: {
                color: {
                  value: "#fff",
                },
              },
              fpsLimit: 120,
              particles: {
                color: {
                  value: "#000",
                },
                links: {
                  color: "#000",
                  distance: 150,
                  enable: true,
                  opacity: 0.2,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 1000,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.1,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
        </div>
      </Fade>
    </>
  );
}

export default App;
